import React, { useRef, useEffect, useState, createRef, useCallback} from 'react';
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    Pagination
} from 'swiper';
import 'swiper/css';
import './swiper.css';
// Locals
import ContentWrapper from './InnerScroll';
import Content from '../Content';
import ScrollPrompt from './ScrollPrompt';
const Scroller = ({
    id,
    panels,
    nodeContentImages,
    popupActive,
    setActiveTag,
    onSlideChange
}) => {
    const swiperContainer = useRef();
    const [childContainerRefs, setChildContainerRefs] = useState([]);

    useEffect(() => { 
        // Populate child container refs
        if(panels.length) {
            setChildContainerRefs(childContainerRefs=>Array(panels.length).fill().map((_, i) => childContainerRefs[i] || createRef()));
            console.debug('PANELS', 'panels:', panels, 'childContainerRefs:', childContainerRefs); 
        }
    }, [panels]);

    return (
        <>
            <Swiper
                modules={[Pagination]}
                key={`${id}-scroller`}
                ref={swiperContainer}            
                // observer={true}             
                // observeParents={true}          
                // observeSlideChildren={true}
                className="scroller swiper-container"
                direction={'vertical'}
                onSlideChange={onSlideChange}
                pagination={{
                    "clickable": true
                }}
                onScroll={()=>console.log('this shouldn\'t trigger')}
            >
                {panels.map((panel, i) => (
                    <SwiperSlide key={`slide-${i}`}>
                        <ContentWrapper parentRef={swiperContainer} ref={childContainerRefs[i]}>
                            <Content setActiveTag={setActiveTag} {...panel} />
                        </ContentWrapper>                       
                    </SwiperSlide>
                ))}
            </Swiper> 
            {swiperContainer?.current?.swiper?.realIndex < (panels.length - 1) && (
                <ScrollPrompt key={`${id}-scroll-prompt`} onClick={()=>swiperContainer?.current?.swiper?.slideNext(500)} />
            )}
        </>
    )
}

export default Scroller;