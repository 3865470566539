import React, { forwardRef, useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, FreeMode } from "swiper";
import _debounce from 'lodash/debounce';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';
import './swiper.css';
const InnerScroll = forwardRef(({ parentRef, children }, ref) => {

    // DODGY SWIPER RECALC CODE FOR CHILD COMPONENT RENDER UPDATE
    const swiperRef = useRef();

    const observer = useRef([]);
    const attachObserver = (target, options = {attributes: true, childList: true, characterData: true}) => {
        const newObserver = new MutationObserver((mutationsList, observer)=> {
            mutationsList.forEach(mutation=>{
                console.debug('INNER SCROLL: MUTATION', 'mutation:', mutation);
                if(mutation.type !== 'style') {//'attributes') {
                    refreshSwiper('mutation');
                    //swiperRef.current?.updateAutoHeight(500);
                }
            });
        });
        newObserver.observe(target, options);
        return newObserver;
    }
    useEffect(()=>{
        observer.current.push(attachObserver(parentRef.current));
        console.log('EL', swiperRef.current?.$wrapperEl[0]?.children?.[0]);
        
        console.log('SWIPEY BOI READY', swiperRef.current);
        console.log('PARENTS', swiperRef.current?.$el.parents());
        swiperRef.current?.$wrapperEl[0]?.children?.[0] && observer.current.push(
            attachObserver(
                swiperRef.current?.$wrapperEl[0]?.children?.[0], 
                {
                    attributes: false, 
                    childList: true, 
                    characterData: true,
                    subtree: true
                }
            ));
        swiperRef.current?.el?.parentNode && observer.current.push(attachObserver(swiperRef.current.el.parentNode));
        return () => {
            if(observer.current.length) {
                observer.current.forEach(observerInstance=>observerInstance.disconnect())
                observer.current.splice(0, observer.current.length);
            }
        }
    }, [swiperRef.current]);


    const refreshSwiper = (reason) => {
        console.debug('INNER SCROLL: REFRESH', swiperRef.current, reason);
        swiperRef?.current?.update();
    }
    
    const scrollDebounce = useRef(null);
    const [prevFlag, setPrevFlag] = useState(swiperRef?.current?.isBeginning);
    const [nextFlag, setNextFlag] = useState(swiperRef?.current?.isEnd);
    const edgeScroll = (swiper, scrollDown) => {
        console.debug('EDGE SCROLL CHECK');
        if (!scrollDown && swiper.isBeginning) {
            if (prevFlag) {
                console.debug("EDGE SCROLL - SLIDE PREV");        
                setPrevFlag(false);
                parentRef?.current?.swiper.slidePrev(600);
            } else {
              setPrevFlag(true);
              setNextFlag(false);
            }
        } else if (scrollDown && swiper.isEnd) {
            if (nextFlag) {
                console.debug("EDGE SCROLL - SLIDE NEXT");
                setNextFlag(false);
                parentRef?.current?.swiper.slideNext(600);
            } else {
              setNextFlag(true);
              setPrevFlag(false);
            }
        } else {
            console.debug("EDGE SCROLL - CANCEL");       
            setPrevFlag(false);
            setNextFlag(false);
        }
    };

    const handleInit = (swiper) => {
        console.debug('INNER SCROLL: INIT SWIPER')
        swiperRef.current = swiper;
    };

    const handleScroll = (swiper, event) => {
        scrollDebounce.current && clearTimeout(scrollDebounce.current);
        const scrollDown = event.deltaY >= 0;
        scrollDebounce.current = setTimeout(
            () => edgeScroll(swiper, scrollDown),
            50
        );
    };
    return (
        <Swiper
            ref={ref}
            modules={[Pagination, Mousewheel, FreeMode]}
            direction={"vertical"}
            slidesPerView={"auto"}
            autoHeight={true}
            observer={true}
            freeMode={{
                enabled: true,
                fluid: true,
                minimumVelocity: 0.2,
                momentum: false
            }}
            mousewheel={{
              sensitivity: 1,
              releaseOnEdges: true,
              thresholdDelta: 1000,
              thresholdTime: 500
            }}
            observer={true}
            observeSlideChildren={true}
            resizeObserver={true}
            className="content-wrapper swiper-container-v"

            onScroll={handleScroll}
            onAfterInit={handleInit}

            onLazyImageLoaded={(swiper)=>refreshSwiper('lazy')}
            onResize={(swiper)=>refreshSwiper('resize')}
        >
            <SwiperSlide className="swiper-slide-child">{children}</SwiperSlide>
        </Swiper>
    );
});
InnerScroll.displayName = 'InnerScroll';
export default InnerScroll;
