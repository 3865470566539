import React from 'react';
import styled from "styled-components";
import Markdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
// import remarkUnwrapImages from 'remark-unwrap-images';
import CalloutIcon from '../../images/icons/svg/_callout.svg'
import FoundationIcon from '../../images/icons/svg/_foundation.svg';
import InitiativeIcon from '../../images/icons/svg/_initiative.svg';
import ObjectiveIcon from '../../images/icons/svg/_objective.svg';
import TargetIcon from '../../images/icons/svg/_target.svg';
import VisionIcon from '../../images/icons/svg/_vision.svg';
import MeasureIcon from '../../images/icons/svg/_measure.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// Content Areas
const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`;
const Type =  styled.h1`
    text-align: left;
    font-size: 1.33rem;
    text-transform: uppercase;
    line-height:2rem;
    width: calc(100% - 6em);
    margin-left: 0.5em;
    color:#FFF;
`;
const TypeIcon = styled.div`    
    width: auto;
    max-width: 5em;
`;
const Tags = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: flex-start;
    flex-wrap: wrap;
`;
const Tag = styled.a`
    cursor: pointer;
    border: 0.15em solid ${props=>props.color};
    border-radius: 1em;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    padding: 0.5em;
    margin: 0.5em 0.5em 1em 0;
`;
const InfoTitle = styled.h2`
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight:700;
    font-family: var(--header-text);
    text-transform: uppercase;
    color:#FFF;
`;
// Content Styled Elements
const Heading = styled.h3`
    font-size: 1rem;
    font-family: var(--body-text);
    font-weight: 400;
    margin-bottom: 1rem;
`;
const SubHeading = styled.h4``;
const SubSubHeading = styled.h5`
    font-size: 0.7rem;
    font-weight: 400;
`;
const Paragraph = styled.p`
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: 0.85rem;
    font-family: var(--body-text);
    font-weight: 400;
`;
const Strong = styled.strong`
    font-size: 0.8rem;
    font-family: var(--body-text);
    color: white;
`;
const ListItem = styled.li`
    font-size: 0.85rem;
    margin: 0.25em;
`;
const Table = styled.table`
    margin-top: 0.5em;
    width: 100%;
    
    border-collapse: unset;
    border-spacing: 0.1em;

    border: 0.05em solid var(--body-color);
    border-radius: 0.25em;

    & tr {
        border-top: 0.01em solid var(--body-color);
    }
    & tr > td,th {
        padding: 0.5em;
    }
    & tbody > tr:nth-child(2) {
        background-color: var(--background-primary);
    ]
`;
const Image = styled.img`
  
    object-fit: contain;
    vertical-align: middle;
    width: fit-content;
    height: auto;
    max-width:100%;
`;
const Content = ({
    setActiveTag,    
    id,
    color,
    title,
    type,
    tags,
    content,    
    content_images
}) => {
    const isCube = false;
    const Icon = ({type, color}) => {
        switch(type) {
            case 'callout': return <CalloutIcon style={{fill: color, width:'3em'}} />;
            case 'foundation': return <FoundationIcon style={{fill: color, width:'3em'}} />;
            case 'initiative': return <InitiativeIcon style={{fill: color, width:'3em'}} />;
            case 'ambition': return <ObjectiveIcon style={{fill: color, width:'3em'}} />;
            case 'target': return <TargetIcon style={{fill: color, width:'3em'}} />;
            case 'vision': return <VisionIcon style={{fill: color, width:'3em'}} />;
            case 'measurable': return <MeasureIcon style={{fill: color, width:'3em'}} />;
            default: return null;
        }
    }
    const tagClickHandler = (tag)=>{
        setActiveTag&&setActiveTag(tag);
    }
    
    return (
            <>
                <Header>
                    <TypeIcon key={`${id}-type`} nodeType={type}><Icon type={type} color={color}/></TypeIcon>
                    <Type key={`${id}-group`}>{type}</Type>
                    <Tags>
                        {tags?.map(({id, name, color})=>(
                            <Tag 
                                key={`tag-${id}`} 
                                color={color?JSON.parse(color)?.css : '#000'} 
                                id={id} 
                                onClick={()=>tagClickHandler(id)}
                            >
                                {name}
                            </Tag>
                        ))}
                    </Tags>
                </Header>
                <InfoTitle key={`${id}-title`}>{title}</InfoTitle>
                <Markdown 
                    //remarkPlugins={[remarkUnwrapImages]} 
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                    key={`${id}-markdown`} 
                    children={content}
                    components={{
                        h1: ({node, ...props}) => <Heading {...props} />,
                        h5: ({node, ...props}) => <SubSubHeading {...props} />,
                        p: ({node, ...props}) => <Paragraph {...props} />,
                        strong: ({node, ...props}) => <Strong {...props} />,
                        li: ({node, ...props}) => <ListItem {...props} />,
                        table: ({node, ...props}) => <Table {...props} />,
                        img: ({node, ...props}) => {
                            const image = getImage(content_images.find(element => element.base === props.src));
                            return (
                                <GatsbyImage 
                                    image={image}
                                    key={`${props.alt}-img`} 
                                    alt={props.alt} 
                                />
                            )
                        }
                    }}
                    {...isCube && { transformLinkUri: (href,children,title)=>'javascript:;' }}
                />
                
            </>
    
    )
};
Content.displayName = 'Content';
export default Content;