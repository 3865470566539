import React, {useState, useEffect} from "react";
import { useMatch } from "@reach/router";
import { MATCH_STORY } from "../../constants/match";
import MobileHeader from "./MobileHeader";
import HeroContent from './HeroContent';
import useWindowSize from '../../hooks/useWindowSize';
import './header.css';
export default function Header({    
    storyData,
    content
}) {
    //const navigate = useNavigate();
    const story = useMatch(MATCH_STORY)?.story;
    const {width} = useWindowSize();
    const [showHero, setShowHero] = useState(width >= 960);
    useEffect(()=>{
        if(width >= 960) {
            // Auto open hero when larger display
            setShowHero(true);
        } else if(!!story) {
            // Auto close hero when sidebar (popup) active
            setShowHero(false);
        }
    }, [width, story]);
    const handleHeroToggle = () => {
        setShowHero(!showHero);
    }
    return (
        <>
            <HeroContent
                show={showHero}
                expanded={!story || width < 960}
                options={storyData}
                content={content}
            />
            <MobileHeader
                key="mobile-header"
                expanded={showHero}
                options={storyData}
                onMenuToggle={handleHeroToggle}
                content={content}
            />
        </>
    );
}