import React, { useEffect, useState } from 'react';
import {useMatch, useNavigate} from '@reach/router';
import classNames from 'classnames';
// Locals
import Scroller from './Scroller';
import './sidebar.css';
import { StaticImage } from 'gatsby-plugin-image';
import { MATCH_NODE, MATCH_STORY } from '../../constants/match';
const Sidebar = ({
    storyData = [],
    graphData = [],    
    setActiveTag,
    nodeContentImages,
    handleNodeChange    
}) => {
    const navigate = useNavigate();
    const story = useMatch(MATCH_STORY)?.story;
    const node = useMatch(MATCH_NODE)?.node;
    const [activeStory, setActiveStory] = useState(0);
    const [activeNode, setActiveNode] = useState(0);

    useEffect(() => {
        // Story param change triggers slider update
        console.debug("SIDEBAR: STORY CHANGE", story, storyData);

        // Find active story
        if(story) {
            const storyItem = storyData.find((node, i)=>{
                if(node?.slug === story) {
                    setActiveStory(i)
                    return true;
                } else return false;
            });
            if(storyItem) {
                console.debug("SIDEBAR: STORY NODE CHANGE", storyItem);
                handleNodeChange(storyItem.nodes[0].id, true);
            }
        }
    }, [story]);

    useEffect(()=>{
        // Node param change triggers slider update
        console.debug("SIDEBAR: NODE CHANGE", graphData, node);

        // Find active node and save index
        if(node && graphData?.nodes?.length) {
            const nodeItemIndex = graphData.nodes.findIndex((item, i)=>(item?.id === node));
            nodeItemIndex >= 0 && setActiveNode(nodeItemIndex);
        }
    }, [node]);

    const handleSlideChange = ({realIndex}) => {
        handleNodeChange(storyData[activeStory].nodes[realIndex].id);
    }
    
    const handleBack = () => {
        navigate('/');
    }

    const sidebarClasses = classNames(
        "sidebar",
        {
            'active': !!(story || node)
        }
    );
    return (  
        <aside className={sidebarClasses}>
            <button className="back-button" onClick={handleBack}>
                <StaticImage alt="go back" src="../../images/back-icon.svg"/> Back
            </button>
            {story && storyData?.[activeStory]?.nodes ? (
                <Scroller 
                    key="story-scroller"
                    id={story}
                    panels={storyData[activeStory]?.nodes} 
                    onSlideChange={handleSlideChange}
                    setActiveTag={setActiveTag}
                />
            ) : node && graphData?.nodes?.[activeNode] && (
                <Scroller 
                    key="single-node-scroller"
                    id={node}
                    panels={[graphData.nodes[activeNode]]} 
                    onSlideChange={handleSlideChange}
                    setActiveTag={setActiveTag}
                />
            )}
        </aside>
    )
}
Sidebar.displayName = 'Sidebar';
export default Sidebar;