import React, {useRef, useState, useEffect} from "react";
import classNames from 'classnames';
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Select from '../Select';
import './heroContent.css';
const SubHeader = styled.div`
    color:#FFFFFF;
    font-size:.8em;
    padding:1rem;
    background: rgb(216, 39, 98, .7);    
    line-height:1.5em;
`;
export default function HeroContent({
    show,
    expanded,
    options,
    content
}) {
    const timeout = useRef(null);
    const [hidden, setHidden] = useState(!show);
    useEffect(()=>{
        if(!show)
            timeout.current = setTimeout(()=>setHidden(true), 500);
        else setHidden(false);
        return () => {
            timeout.current && clearTimeout(timeout.current);
            timeout.current = null;
        }
    }, [show]);
    const heroContainerClasses = classNames('hero-container', {
        active: show,
        hidden: hidden,
        small: !expanded
    });
    const expandAreaClasses = classNames('expand-area', {
        active: expanded
    });
    return (
        <div className={heroContainerClasses}>
            <div className="logo">
                <StaticImage 
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%"
                    }}
                    alt="queensland government logo" 
                    src="../../../images/gov-logos-01.svg" 
                    //placeholder="blurred" 
                />
            </div>
            <div className={expandAreaClasses}>
                <h1>{content?.title}</h1>
                <SubHeader>{content?.description}</SubHeader>
            </div>               
            <h2>{content?.callout}</h2>
            <Select  
                options={options}
            />
        </div>
    );
}