import React from 'react';
import styled from "styled-components";
import './scroll-prompt.css';
const ArrowDown = styled.div`
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #FFF;
`;
const ScrollPrompt = ({
    onClick
}) => {
    return (        
        <div className="scroll-prompt" onClick={()=>onClick && onClick()} onKeyDown={()=>onClick && onClick()}>            
            <img 
                alt="scroll prompt" 
                src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='152' viewBox='0 0 96 152' fill='none'%3E%3Crect x='4' y='4' width='88' height='144' rx='44' fill='none' stroke='%23FFFFFF' stroke-width='8'/%3E%3Cline x1='48' y1='16' x2='48' y2='64' stroke='%23FFFFFF' stroke-dasharray='50' stroke-width='8'%3E%3Canimate attributeType='CSS' attributeName='stroke-dashoffset' from='100' to='0' dur='2s' repeatCount='indefinite'/%3E%3C/line%3E%3C/svg%3E"
            />
            SCROLL
            <ArrowDown />
        </div>
    )
};
ScrollPrompt.displayName = 'ScrollPrompt';
export default ScrollPrompt;