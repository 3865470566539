import React from 'react';
import { useMatch, useNavigate } from '@reach/router';
import { MATCH_STORY } from '../../../constants/match';
import './select.css';
export default function StorySelect({
    options = []
}) {
    const navigate = useNavigate();
    const story = useMatch(MATCH_STORY)?.story || 'no_story';
    const handleSelect = (event) => {
        const slug = event.target.value;
        console.debug('SELECT STORY', slug);
        if(slug !== "no_story") {
            navigate(`/story/${slug}`);
        } else {
            navigate('/');
        }
    }
    return (
        <div className="story-select-container">
            <select
                title="Story Select"
                name="story-select" 
                className="story-select"
                value={story}
                onChange={handleSelect}
            >
                <option key="no_story" value="no_story">Who Are You?</option>
                {options.map(({id, slug, title}) => <option key={id} value={slug}>{title}</option>)}
            </select>
        </div>
    )
}