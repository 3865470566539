import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import Select from './Select';
import './header.css';
export default function MobileHeader({
    expanded,
    options,
    onMenuToggle
}) {
    return (
        <header className="mobile-header">
            <StaticImage className="mobile-logo" alt="queensland government logo" src="../../images/QLD-Gov-Logo.svg"/>
            <nav>
                <Select options={options} />
                <button onClick={onMenuToggle} className="mobile-menu-button">{
                    expanded ? (
                        <StaticImage style={{objectFit: 'contain'}} alt="close menu" src="../../images/close-icon.svg"/>
                    ) : (
                        <StaticImage style={{objectFit: 'contain'}} alt="open menu" src="../../images/menu-icon.svg"/>
                    )}
                </button>
            </nav>
        </header>
    )
}